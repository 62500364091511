<template>
  <div>
    <div v-for="(con, i) in messageList" :key="i">
      <!-- is Message From System -->
      <div v-if="con.userType == 'system'" class="px-2 mb-3">
        <div v-if="con.systemType == systemType.TicketUpdated">
          <div class="separator ticket">
            <span
              class="font-italic mr-1"
              @click="showFormTicket(con.ticketUrl)"
              >{{ con.message }}</span
            >
          </div>
        </div>

        <div v-else-if="con.adminName.trim() == ''">
          <div class="separator">
            <span class="font-italic mr-1">{{ con.message }}</span>
          </div>
        </div>
        <div v-else-if="con.systemType == systemType.AdminLeaveGroupMention">
          <div class="separator">
            <span class="admin-name mr-1">{{ con.adminName }}</span>
            <span class="font-italic">{{ con.message }}</span>
          </div>
        </div>
        <div v-else-if="con.systemType == systemType.AssignTo">
          <div class="separator">
            <span class="font-italic mr-1"
              >{{ con.message }}
              <span class="admin-name">{{ con.toAdminName }}</span> by</span
            >
            <span class="admin-name">{{ con.adminName }}</span>
          </div>
        </div>
        <div
          v-else-if="
            con.systemType == systemType.MoveToPending ||
              con.systemType == systemType.MoveToClose ||
              con.systemType == systemType.MoveToCase
          "
        >
          <div class="separator">
            <span class="font-italic mr-1">{{ con.message }}</span>
            <span class="mx-1 font-italic">by</span>
            <span class="admin-name">{{ con.adminName }}</span>
          </div>
        </div>
        <div v-else-if="con.topicId">
          <div class="separator">
            <span class="font-italic mr-1">{{ con.message }}</span>
            <span class="admin-name">{{ con.topicName }}</span>
            <span class="mx-1 font-italic">by</span>
            <span class="admin-name">{{ con.adminName }}</span>
          </div>
        </div>
        <div v-else-if="con.toAdminName && con.toAdminName.trim()">
          <div class="separator">
            <span class="font-italic mr-1">{{ con.message }}</span>
            <span v-if="con.toAdminName != con.adminName" class="admin-name">{{
              con.toAdminName
            }}</span>
            <span
              v-if="con.toAdminName != con.adminName"
              class="mx-1 font-italic"
              >by</span
            >
            <span class="admin-name">{{ con.adminName }}</span>
          </div>
        </div>
        <div v-else class="separator">
          {{ con.message }}
          <span class="mx-1">by</span>
          <span class="admin-name">{{ con.adminName }}</span>
        </div>
        <div class="end-chat-time text-center">
          <b-spinner
            v-if="con.isWaitSend"
            label="sending..."
            class="sending-chat"
          ></b-spinner>
          <div
            v-else-if="con.isSendFail"
            class="send-fail justify-content-center"
          >
            ส่งไม่สำเร็จ
            <font-awesome-icon icon="exclamation-circle" />
          </div>
          <ChatDateTime v-else :date="con.createdTime" />
        </div>
      </div>
      <!-- <div class="px-2 mb-3">
        <div class="">
          {{ con.message }}
        </div>
      </div> -->
      <!-- is Text From Me -->
      <!-- v-else-if="con.isAdmin || con.isDummy" -->
      <div
        v-if="con.userType == 'admin'"
        class="d-flex align-items-end flex-column justify-content-end mb-4"
      >
        <!-- <div v-if="con.isDummy" class="my-auto pr-2 pl-3">
          <div class="spinner-grow text-secondary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->

        <div
          :class="[
            con.messageType == 4
              ? 'msg_cotainer_send_img'
              : con.isPrivateChat
              ? 'msg_cotainer_send_private'
              : 'msg_cotainer_send'
          ]"
        >
          <span
            v-if="
              con.messageType == 1 ||
                con.messageType == 21 ||
                con.messageType == 16 ||
                con.messageType == 22
            "
            class="text-nextline"
            v-html="con.message"
          ></span>
          <div v-if="con.messageType == 4">
            <div class="img_upload" @click="openModalImage(con.filePath)">
              <div
                class="img_upload-display"
                v-bind:style="{
                  backgroundImage: 'url(' + con.filePath + ')'
                }"
              ></div>
            </div>
          </div>
          <!-- Other Type -->
          <!-- messageType = 21 postback -->
          <div
            v-if="
              con.messageType != 1 &&
                con.messageType != 4 &&
                con.messageType != 21 &&
                con.messageType != 16 &&
                con.messageType != 22
            "
          >
            <ChatBoxWidget :con="con" />
          </div>

          <!-- con.messageResponse.content.responseType == 8 ? 'extent-width' : '' -->
          <!-- <div
            v-if="
              con.messageResponse.content.responseType == 6 ||
                con.messageResponse.content.responseType == 2
            "
          >
            <div
              v-for="(item, i) in con.messageResponse.content.imageList"
              :key="i"
              class="img_upload"
            >
              <div
                class="img_upload-display"
                v-bind:style="{
                  backgroundImage: 'url(' + item.imageUrl + ')'
                }"
              ></div>
            </div>
            <div
              v-if="con.messageResponse.content.responseType == 2"
              class="text-right"
            >
              <span
                class="ml-auto bg-white text-dark p-2 mt-1 rounded text-black"
                >{{ con.messageResponse.content.text }}
              </span>
            </div>
          </div> -->
          <!-- <ChatBoxWidget :con="con" v-else /> -->
        </div>
        <span class="msg_time_send text-nowrap">
          <b-spinner
            v-if="con.isWaitSend"
            label="sending..."
            class="sending-chat"
          ></b-spinner>
          <div v-else-if="con.isSendFail" class="send-fail">
            ส่งไม่สำเร็จ
            <font-awesome-icon icon="exclamation-circle" />
          </div>
          <ChatDateTime v-else :date="con.createdTime" />
          <span v-if="con.adminName">
            (<span>{{ con.adminName }}</span
            >)
          </span>
          <font-awesome-icon v-if="con.isSendSuccess" icon="check" />
        </span>
      </div>
      <!-- is Text From Other -->
      <div
        v-else-if="con.userType == 'user'"
        class="d-flex justify-content-start mb-4 px-2"
      >
        <div class="img_cont_msg">
          <div
            v-if="otherInfo.imageUrl && otherInfo.imageUrl.trim()"
            class="rounded-circle user_img_msg"
          >
            <div
              class="rounded-circle user_img_display"
              v-bind:style="{
                backgroundImage: 'url(' + otherInfo.imageUrl + ')'
              }"
            ></div>
          </div>
          <font-awesome-icon
            v-else
            :icon="['fas', 'user']"
            class="rounded-circle user_img_msg p-1"
          />
        </div>
        <div
          :class="[
            con.messageType == 4 || con.messageType == 2
              ? 'msg_cotainer_img'
              : 'msg_cotainer'
          ]"
        >
          <span
            v-if="
              con.messageType == 1 ||
                con.messageType == 21 ||
                con.messageType == 16 ||
                con.messageType == 22
            "
            class="text-nextline"
            v-html="con.message"
          ></span>
          <div v-if="con.messageType == 4" class="img_upload">
            <div
              @click="openModalImage(con.filePath)"
              class="img_upload-display"
              v-bind:style="{
                backgroundImage: 'url(' + con.filePath + ')'
              }"
            ></div>
          </div>
          <!-- Other Type -->
          <!-- messageType = 21 postback -->
          <div
            v-if="
              con.messageType != 1 &&
                con.messageType != 4 &&
                con.messageType != 21 &&
                con.messageType != 16 &&
                con.messageType != 22
            "
          >
            <ChatBoxWidget :con="con" />
          </div>

          <span class="msg_time text-nowrap">
            <ChatDateTime :date="con.createdTime" />
          </span>
        </div>
      </div>
    </div>
    <ChatBoxModalImage ref="ChatBoxModalImage" :path="path" />
    <ModalTicketIframe ref="ModalTicketIframe" />
  </div>
</template>

<script>
import ChatDateTime from "@/components/chat/ChatDateTime";
import ChatBoxWidget from "@/components/chat/chatBox/ChatBoxWidget";
import ChatBoxModalImage from "@/components/chat/chatBox/modal/ChatBoxModalImage";
import ModalTicketIframe from "@/components/chat/chatOrder/chatOrderProfile/modal/ModalTicketIframe";
import { SYSTEM_TYPES } from "@/helpers/constants.js";

export default {
  name: "ChatBoxMessageList",
  components: {
    ChatDateTime,
    ChatBoxWidget,
    ChatBoxModalImage,
    ModalTicketIframe
  },
  props: {
    messageList: {
      required: true,
      type: Array
    },
    meProfile: {
      required: true,
      type: Object
    },
    otherInfo: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      path: "",
      systemType: SYSTEM_TYPES
    };
  },
  methods: {
    openModalImage(path) {
      this.path = path;
      this.$refs.ChatBoxModalImage.show();
    },
    showFormTicket(ticketUrl, isCreate = false) {
      let url = `${ticketUrl}?email=${this.$store.state.chatAdminProfile.email}`;
      this.$refs.ModalTicketIframe.show(url, isCreate);
    }
  }
};
</script>

<style lang="scss" scoped>
.msg_cotainer_send_img {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  padding: 2px 10px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 80%;
}
.msg_cotainer_send_img div {
  display: flex;
  width: 100%;
  flex-flow: column;
}
.msg_cotainer_send_img div .img_upload {
  margin-left: auto;
  height: auto;
  max-height: 20rem;
  width: 100%;
  max-width: 30rem;
}
.msg_cotainer_send_img div .img_upload .img_upload-display {
  width: 100%;
  padding-bottom: 56.25%;
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  background-color: inherit !important;
  cursor: pointer;
}
.msg_cotainer_send,
.msg_cotainer_send_private {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  padding: 10px;
  position: relative;
  max-width: 82%;
  min-width: 25%;
  font-size: 14px;
}

.msg_cotainer_send_private {
  background-color: #fff1de !important;
}

.extent-width {
  width: 70% !important;
}
.spinner-grow {
  width: 20px;
  height: 20px;
}
.msg_wrap {
  white-space: pre-line;
  overflow-wrap: break-word;
}
.msg_time_send {
  margin-right: 15px;
  color: #bfbfbf;
  font-size: 10px;
}
.msg_cotainer_send_img .msg_time_send {
  padding-right: 10px;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.user_img_display {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}
.msg_cotainer_img {
  margin-top: auto;
  margin-bottom: auto;
  // margin-left: auto;
  padding: 2px 10px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 80%;
}
.msg_cotainer_img div {
  display: flex;
  width: 100%;
  flex-flow: column;
}
.msg_cotainer_img .img_upload {
  margin-right: auto;
  height: auto;
  max-height: 20rem;
  width: 100%;
  max-width: 30rem;
}
.msg_cotainer_img .img_upload .img_upload-display {
  width: 100%;
  padding-bottom: 56.25%;
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  background-color: inherit !important;
}
.img_cont_msg {
  height: 40px;
  width: 40px;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 4px;
  background-color: #b3fffd;
  padding: 10px;
  position: relative;
  max-width: 82%;
  min-width: 25%;
  font-size: 14px;
}
.msg_time {
  position: absolute;
  left: 0;
  bottom: -15px;
  color: #bfbfbf;
  font-size: 10px;
}
.msg_cotainer_img .msg_time {
  padding-left: 10px;
}
.loading-dummy-img {
  position: absolute;
  width: 20%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.separator,
.separator-noline {
  text-align: center;
  color: #bfbfbf;
  font-size: 14px;
  .admin-name {
    color: #000000;
    font-weight: 600;
  }
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #cccccc;
}

.separator:not(:empty)::before {
  margin-right: 0.4em;
}

.separator:not(:empty)::after {
  margin-left: 0.4em;
}
.separator.ticket {
  cursor: pointer;
}
.separator.ticket:hover {
  text-decoration: underline;
}
.end-chat-time {
  text-align: center;
  color: #bfbfbf;
  font-size: 11px;
}
.icon-check {
  color: #009f00;
  margin-right: 0.25rem;
}
.text-black {
  color: #000 !important;
}
::v-deep .text-nextline {
  white-space: pre-line;
  a {
    color: #000;
    text-decoration: underline;
  }
}
.sending-chat {
  margin-top: 3px;
  width: 0.5rem;
  height: 0.5rem;
}
.send-fail {
  display: flex !important;
  flex-flow: row !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding-top: 4px;
  font-size: 10px;
  color: #f24726;
  svg {
    margin-left: 3px;
  }
}
@media (max-width: 767.98px) {
}
</style>
