<template>
  <b-modal
    size="lg"
    hide-footer
    title="Agents"
    v-model="localShowModal"
    @hide="hide"
    ref="modal-agents"
    id="modal-agents"
  >
    <div class="text-center">
      <div class="text-left">
        <InputText
          id="input-search-mention"
          v-model="textSearch"
          textFloat="Search Admin"
          placeholder="Staff ID, Email"
          type="text"
          icon="search"
          @submit="onSearchAdmin"
        />
      </div>
      <div
        class="ext-left w-full pb-3 align-items-center d-flex justify-content-start"
        v-if="selectedAdmins.length > 0 && visibleButtons"
      >
        <b-button
          size="sm"
          class="btn-outline-error mr-2"
          @click="handleClickLeave(selectedAdmins)"
          ><div>
            <img
              style="height: 20px;"
              class="icon-red mr-2"
              :src="require('@/assets/images/arrow-right-to-bracket-solid.svg')"
              alt
            />Delete From Group
          </div></b-button
        >
        <span>{{ selectedAdmins.length }} Selected</span>
        <span class="ml-auto btn-cancel-select-chat" @click="handleSelectAll()"
          >Cancel</span
        >
      </div>
      <div class="panel-table">
        <b-table
          striped
          hover
          show-empty
          :fields="fields"
          :items="paginatedData"
          empty-text="Empty Data"
          responsive
          class="table-main"
        >
          <template v-slot:head(selected)>
            <b-form-checkbox
              v-if="visibleButtons"
              v-model="selectedAdminsAll"
              @change="handleSelectAll"
            />
          </template>
          <template v-slot:cell(selected)="data">
            <b-form-checkbox
              v-if="visibleButtons"
              :id="`checkbox-${data.item.id}`"
              :name="`checkbox_${data.item.id}`"
              class="checkbox-select-chat"
              :unchecked-value="false"
              :value="data.item.id"
              v-model="selectedAdmins"
              @change="$event => handleChangeSelectChat($event, data.item.id)"
            />
          </template>
          <template v-slot:cell(name)="data">
            <div class="text-left">
              <div class="name">
                {{ formatFullName(data.item) }}
                <span v-if="isShowAdminStatus">
                  <b-badge
                    v-if="data.item.isAvailable"
                    pill
                    variant="online"
                    class="badge-status"
                    >Online</b-badge
                  >
                  <b-badge v-else pill variant="offline" class="badge-status"
                    >Offline</b-badge
                  >
                </span>
              </div>
              <p class="email">{{ data.item.email }}</p>
            </div>
          </template>
          <template v-slot:cell(team)="data">
            <div class="text-left">
              <div class="name">
                {{ formatTeamName(data.item.team) }}
              </div>
            </div>
          </template>
          <template v-slot:cell(action)="data">
            <div class="text-left leave-icon w-100">
              <img
                v-if="visibleButtons"
                style="height: 20px;"
                class="icon-red"
                :src="
                  require('@/assets/images/arrow-right-to-bracket-solid.svg')
                "
                @click="handleClickLeave([data.item.id])"
                alt
              />
            </div>
          </template>
        </b-table>
      </div>
      <div
        class="d-flex justify-content-between align-items-center w-full"
        id="footer-modal-agents"
      >
        <p class="per-page">
          แสดงผล {{ (pagination.pageNo - 1) * pagination.perPage + 1 }} ถึง
          {{
            Math.min(
              pagination.pageNo * pagination.perPage,
              filteredData.length
            )
          }}
          จากทั้งหมด {{ filteredData.length }} รายการ
        </p>
        <div class="">
          <div
            id="pagination-modal-agents"
            class="w-full d-flex justify-content-end pagination-main"
          >
            <b-pagination
              class="w-auto"
              v-model="pagination.pageNo"
              :total-rows="filteredData.length"
              :per-page="pagination.perPage"
              @change="handlePagination"
            ></b-pagination>
            <b-form-select
              class="ml-md-3 w-auto"
              v-model="pagination.perPage"
              @change="handleChangePerPage"
              :options="pageOptions"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import InputText from "@/components/inputs/InputText";
export default {
  name: "ModalAgents",
  components: { InputText },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
      required: false
    },
    items: {
      type: Array,
      default: () => []
    },
    lastAdminId: {
      type: String,
      required: false,
      default: null
    },
    adminProfileExternalId: {
      type: String,
      required: false,
      default: null
    },
    isShowAdminStatus: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      localShowModal: this.isShowModal, // สร้างตัวแปร local เก็บค่า prop
      fields: [
        { key: "selected", label: "", thStyle: { width: "10%" } },
        { key: "name", label: "Name / Email", thStyle: { width: "50%" } },
        { key: "team", label: "Team", thStyle: { width: "30%" } },
        { key: "action", label: "", thStyle: { width: "10%" } }
      ],
      textSearch: "",
      pagination: {
        pageNo: 1,
        perPage: 10
      },
      pageOptions: [
        { value: 10, text: `10 / Page` },
        { value: 30, text: `30 / Page` },
        { value: 50, text: `50 / Page` },
        { value: 100, text: `100 / Page` }
      ],
      selectedAdmins: [],
      selectedAdminsAll: false,
      textState: ""
    };
  },
  computed: {
    filteredData() {
      return this.items.filter(item => {
        return (
          item.firstname.toLowerCase().includes(this.textState.toLowerCase()) ||
          this.formatFullName(item)
            .toLowerCase()
            .includes(this.textState.toLowerCase()) ||
          item.email.toLowerCase().includes(this.textState.toLowerCase())
        );
      });
    },

    paginatedData() {
      const start = (this.pagination.pageNo - 1) * this.pagination.perPage;
      const end = start + this.pagination.perPage;
      return this.filteredData.slice(start, end);
    },
    visibleButtons() {
      return (
        this.lastAdminId &&
        this.adminProfileExternalId &&
        this.lastAdminId === this.adminProfileExternalId
      );
    }
  },
  watch: {
    isShowModal(newVal) {
      this.localShowModal = newVal;
      this.textSearch = "";
    },
    localShowModal(newVal) {
      this.selectedAdmins = [];
      this.selectedAdminsAll = false;
      if (!newVal) {
        this.$emit("hide");
      }
    },
    selectedAdmins(newVal) {
      this.selectedAdminsAll =
        this.paginatedData.length > 0 &&
        newVal.length === this.paginatedData.length;
    },
    paginatedData(newVal, oldVal) {
      if (newVal && oldVal && newVal.length !== oldVal.length) {
        this.selectedAdminsAll = false;

        this.selectedAdmins = this.selectedAdmins.filter(item => {
          return newVal.some(val => val.id === item);
        });
      }
    }
  },
  methods: {
    onSearchAdmin() {
      this.textState = this.textSearch;
    },
    hide() {
      this.localShowModal = false;
    },
    formatFullName(val) {
      return `${val.firstname} ${val.lastname}`;
    },
    formatTeamName(val) {
      return val.map(item => item).join(", ");
    },
    handlePagination(page) {
      this.pagination.pageNo = page;
    },
    handleChangePerPage() {
      this.pagination.pageNo = 1;
    },
    handleClickLeave(data) {
      this.$emit("on-leave-chat", data);
    },
    handleSelectAll(value) {
      if (value) {
        this.selectedAdmins = this.paginatedData.map(item => item.id);
      } else {
        this.selectedAdmins = [];
      }
    },
    handleChangeSelectChat(value, id) {
      let fIndex = value.findIndex(x => x === id);
      if (fIndex < 0) this.selectedAdminsAll = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.email {
  color: #9c9c9c;
  font-size: 12px;
}
.leave-icon {
  color: red;
  cursor: pointer;
}
.icon-red {
  filter: brightness(0) saturate(100%) invert(14%) sepia(92%) saturate(6175%)
    hue-rotate(0deg) brightness(103%) contrast(121%);
}
.btn-cancel-select-chat {
  text-decoration: underline !important;
  color: #832abf !important;
  font-size: 13px;
  cursor: pointer;
}
.per-page {
  width: 35%;
}

@media (max-width: 768px) {
  #footer-modal-agents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  .per-page {
    width: 100%;
  }
}
</style>
