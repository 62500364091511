<template>
  <div :class="['select-custom', { error: isValidate || (v && v.$error) }]">
    <label v-if="title">
      {{ title }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>

    <v-select
      v-model="selectedValue"
      :options="options"
      :reduce="option => option[valueField]"
      :label="textField"
      :disabled="isDisplay"
      class="f-regular"
      :class="{
        'error-border': isValidate || (v && v.$error),
        'ellipsis-enabled': ellipsis
      }"
      @input="onDataChange"
      :placeholder="placeholder"
      :clearable="clearable"
      searchable
    ></v-select>

    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required === false">
        {{ options[0][textField || "text"] }}
      </span>
      <span class="text-error" v-else-if="v.minValue === false">
        {{ options[0][textField || "text"] }}
      </span>
      <span class="text-error" v-if="v.valueDefault === false">
        {{ options[0][textField || "text"] }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "VueSelect",
  props: {
    title: String,
    required: Boolean,
    name: String,
    size: String,
    options: {
      required: true,
      type: Array
    },
    value: [Number, String],
    isRequired: Boolean,
    isValidate: Boolean,
    v: Object,
    valueField: {
      type: String,
      default: "value"
    },
    textField: {
      type: String,
      default: "text"
    },
    isDisplay: Boolean,
    placeholder: {
      type: String,
      default: "Select an option"
    },
    clearable: {
      type: Boolean,
      default: true
    },
    ellipsis: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedValue: this.value
    };
  },
  watch: {
    value(newValue) {
      this.selectedValue = newValue;
    }
  },
  methods: {
    onDataChange(value) {
      this.$emit("onDataChange", value);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  max-width: 100%;

  &:has(.vs__selected) {
    max-width: calc(100% - 41px);
  }
}

.v-select .vs__selected-options {
  flex-wrap: nowrap;
}

::v-deep .ellipsis-enabled .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

::v-deep .vs__search {
  position: absolute;
}

::v-deep .vs--open .vs__search {
  position: static;
}

::v-deep .vs__dropdown-option {
  white-space: normal;
}

.select-custom {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}

.select-custom > label {
  color: #575757;
  font-size: 14px;
  margin-bottom: 2px;
}

/* Custom Styling for v-select */
::v-deep .vs__dropdown-toggle {
  color: #575757;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  height: 40px;
}

/* ✅ ใช้ error-border class ใน v-select */
::v-deep .error-border .vs__dropdown-toggle {
  border-color: red !important;
}

::v-deep .vs__dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

::v-deep .vs__search {
  font-size: 14px;
}

::v-deep .vs__clear {
  color: #ff0000;
}

.text-error {
  color: #ff0000;
  font-size: 14px;
}

/* Responsive */
@media (max-width: 767.98px) {
  .select-custom {
    margin-top: 0;
  }
  .select-custom > label {
    font-size: 15px;
  }
}
</style>
