<template>
  <div class="d-flex ml-1">
    <b-dropdown
      variant="link"
      class="filter-dropdown-setting"
      toggle-class="text-decoration-none"
      no-caret
      right
    >
      <template #button-content>
        <span class="cursor-pointer my-auto text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="20"
            fill="currentColor"
            class="bi bi-three-dots-vertical"
            viewBox="0 0 15 20"
          >
            <path
              d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
            />
          </svg>
        </span>
      </template>
      <b-dropdown-item
        href="#"
        @click="showCheckboxSelectUserChat(true)"
        v-if="
          !displayCheckboxSelectUserChat &&
            [3, 4, 99, 98, 97, 96, 95, 94].includes(chatModeId)
        "
        >Select</b-dropdown-item
      >
      <template v-if="displayCheckboxSelectUserChat">
        <b-dropdown-item
          href="#"
          v-if="[3, 4, 99, 98, 97, 96, 95, 94].includes(chatModeId)"
          @click="handleShowModalAssignTo"
          :disabled="selectedUserChatList.length == 0"
          >Assign To</b-dropdown-item
        >
        <b-dropdown-item
          href="#"
          v-if="[3, 4].includes(chatModeId)"
          :disabled="selectedUserChatList.length == 0"
          @click="btnEndChat(4)"
          >Move to Pending</b-dropdown-item
        >
        <b-dropdown-item
          href="#"
          v-if="[3, 4].includes(chatModeId)"
          :disabled="selectedUserChatList.length == 0"
          @click="btnEndChat(5)"
          >End Chat</b-dropdown-item
        >
      </template>
      <b-dropdown-item href="#" @click="showModal"
        >Chat Setting</b-dropdown-item
      >
    </b-dropdown>

    <b-modal ref="modal-chat-setting" title="Chat setting" centered>
      <div>
        <label class="toggle">
          <input
            v-if="notiForm.isRegister"
            class="toggle-checkbox"
            type="checkbox"
            @change="btnSubscribe(false)"
            v-model="notiForm.isRegister"
          />
          <input
            v-if="!notiForm.isRegister"
            class="toggle-checkbox"
            type="checkbox"
            v-model="notiForm.isRegister"
            @change="btnSubscribe(true)"
          />
          <div class="toggle-switch"></div>
          <span class="toggle-label">{{
            notiForm.isRegister ? "เปิดแจ้งเตือนแชท" : "ปิดแจ้งเตือนแชท"
          }}</span>
        </label>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="hideModal"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <ChatBoxModalAssignTo
      ref="ChatBoxModalAssignTo"
      @selectedStaff="handleSelectedStaff"
      @success="clearSelectUserChat"
      :chatRoomId="chatRoomId"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ChatBoxModalAssignTo from "@/components/chat/chatBox/modal/ChatBoxModalAssignTo";

export default {
  props: {
    chatModeId: {
      required: false,
      type: [Number, String]
    }
  },
  components: {
    ChatBoxModalAssignTo
  },
  data() {
    return {
      notiForm: {
        isRegister: false,
        subscription: null,
        endChatMode: 4
      }
    };
  },
  computed: {
    ...mapGetters({
      displayCheckboxSelectUserChat: "getDisplayCheckboxSelectUserChat",
      selectedUserChatList: "getSelectedUserChatList",
      brandId: "getBrand"
    }),
    chatRoomId() {
      let chatRoomId = this.selectedUserChatList.map(item => {
        return item.id;
      });
      return chatRoomId;
    }
  },
  methods: {
    async showModal() {
      this.notiForm.isRegister = false;
      this.handlePermission();
    },
    hideModal() {
      this.$refs["modal-chat-setting"].hide();
    },
    async permissionGranted() {
      await this.registerNoti();
      this.$refs["modal-chat-setting"].show();
      await this.handleCheckSubscribe();
    },
    async btnSubscribe(val) {
      await this.handleSubscribe(val);
    },
    // async registerNoti_old() {
    //   try {
    //     if ("serviceWorker" in navigator) {
    //       // Register Service Worker
    //       // console.log("Registering service worker...");
    //       const register = await navigator.serviceWorker.register(
    //         "./service-worker.js",
    //         {
    //           scope: "/"
    //         }
    //       );
    //       // console.log("Service Worker Registered...");

    //       // Register Push
    //       // console.log("Registering Push...");

    //       if (register.safari && register.safari.pushNotification) {
    //         this.notiForm.subscription = await register.safari.pushNotification.subscribe(
    //           {
    //             userVisibleOnly: true,
    //             applicationServerKey: this.urlBase64ToUint8Array(
    //               this.$publicVapidKey
    //             )
    //           }
    //         );
    //       } else {
    //         this.notiForm.subscription = await register.pushManager.subscribe({
    //           userVisibleOnly: true,
    //           applicationServerKey: this.urlBase64ToUint8Array(
    //             this.$publicVapidKey
    //           )
    //         });
    //       }
    //       // console.log("this.notiForm.subscription", this.notiForm.subscription);
    //     }
    //   } catch (error) {
    //     console.log("error push noti", error);
    //     // alert(error.message);
    //   }
    // },
    async registerNoti() {
      try {
        if ("serviceWorker" in navigator) {
          const register = await navigator.serviceWorker.register(
            "./service-worker.js",
            {
              scope: "/"
            }
          );

          if (register.safari && register.safari.pushNotification) {
            this.notiForm.subscription = await register.safari.pushNotification.subscribe(
              {
                userVisibleOnly: true,
                applicationServerKey: this.urlBase64ToUint8Array(
                  this.$publicVapidKey
                )
              }
            );
          } else {
            this.notiForm.subscription = await register.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: this.urlBase64ToUint8Array(
                this.$publicVapidKey
              )
            });
          }
        } else {
          console.log("Service workers are not supported.");
        }
      } catch (error) {
        console.log("error push noti", error.message);
      }
    },

    async handleSubscribe(val) {
      console.log("handleSubscribe");
      if (!this.notiForm.subscription) {
        await this.registerNoti();
        // await this.showModal();
      }
      if (!this.notiForm.subscription) {
        this.notiForm.isRegister = false;
        return;
      }

      let body = {
        isRegister: val,
        subscription: this.notiForm.subscription
      };

      // Send Push Notification
      // console.log("Sending Push...");
      // console.log(JSON.stringify(body));
      fetch(`${this.$chatBaseUrl}/setting/subscribe`, {
        method: "POST",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            console.log("Push Sent...");
          }
          console.log("Push Registered...");
        })
        .catch(error => {
          console.log("error push noti", error);
          this.notiForm.isRegister = false;
          alert(error.message);
        });
    },
    async handleCheckSubscribe() {
      if (!this.notiForm.subscription) {
        this.notiForm.isRegister = false;
        return;
      }
      let body = {
        subscription: this.notiForm.subscription
      };

      // Send Push Notification
      // console.log("Sending Push...");
      // console.log(JSON.stringify(body));
      fetch(`${this.$chatBaseUrl}/setting/subscription-data`, {
        method: "POST",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.notiForm.isRegister = result.detail;
          }
        })
        .catch(error => {
          console.log("check-subscribe ", error);
        });
    },
    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    // ------------ End Watest ------------------
    async handlePermission() {
      return navigator.permissions
        .query({ name: "notifications" })
        .then(this.permissionQuery)
        .catch(error => {
          console.log(error.message);
          this.permissionGranted();
        });
    },

    permissionQuery(result) {
      console.debug({ result });
      if (this.checkIOS()) {
        this.permissionGranted();
        return;
      }
      var newPrompt;
      if (result.state == "granted") {
        // notifications allowed, go wild
        // console.log("granted");
        this.permissionGranted();
      } else if (result.state == "prompt") {
        // we can ask the user
        // console.log("prompt");
        newPrompt = Notification.requestPermission();
      } else if (result.state == "denied") {
        // notifications were disabled
        console.log("denied");
        alert("กรุณาเปิดสิทธิ์ Notification ของ Browser");
      }

      result.onchange = () => {
        console.log({ updatedPermission: result });
        // this.showModal();
        window.location.reload();
      };

      return newPrompt || result;
    },
    checkIOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod"
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    showCheckboxSelectUserChat(val) {
      this.$store.dispatch("setDisplayCheckboxSelectUserChat", val);
    },
    handleShowModalAssignTo() {
      this.$refs.ChatBoxModalAssignTo.show();
    },
    handleSelectedStaff() {},
    clearSelectUserChat() {
      this.$emit("showCheckboxSelectUserChat", false);
    },
    btnEndChat(mode) {
      // this.adminMode = false;
      this.endChatMode = mode;
      // this.resetModalUnresolve();
      this.handleChangeModeResolveAndUnresolve();
    },
    // resetModalUnresolve() {
    //   this.unresolveNoteSubject = "";
    //   this.apiTriggerIdSeleted = 0;
    // },
    handleChangeModeResolveAndUnresolve() {
      this.handleChangeMode();
    },
    handleChangeMode() {
      let body = {
        mode: this.endChatMode,
        chatRoomId: this.chatRoomId,
        externalId: this.$store.state.chatAdminProfile.externalId,
        brandId: this.brandId
      };
      this.$store.dispatch("setIsLoading", true);
      fetch(`${this.$chatBaseUrl}/chatroom/bulk/change-mode`, {
        method: "POST",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(result => {
          this.$store.dispatch("setIsLoading", false);
          if (result.result) {
            this.$emit("handleShowCheckboxSelectUserChat", false);
          } else {
            this.handleLoadError();
          }
        })
        .catch(error => {
          this.$store.dispatch("setIsLoading", false);
          this.handleLoadError();
          console.log("error", error);
        });
    }
  }
};
</script>
<style scoped>
#chat-menu-wrap {
  position: relative;
  height: 25px;
  width: 25px;
}

#chat-menu-wrap .dots {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
}

#chat-menu-wrap .dots > div,
#chat-menu-wrap .dots > div:after,
#chat-menu-wrap .dots > div:before {
  height: 6px;
  width: 6px;
  background-color: rgba(49, 49, 49, 0.8);
  border-radius: 50%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#chat-menu-wrap .dots > div {
  position: relative;
}

#chat-menu-wrap .dots > div:after {
  content: "";
  position: absolute;
  bottom: calc((25px / 2) - (6px / 2));
  left: 0;
}

#chat-menu-wrap .dots > div:before {
  content: "";
  position: absolute;
  top: calc((25px / 2) - (6px / 2));
  left: 0;
}

#chat-menu-wrap .menu {
  position: absolute;
  right: -10px;
  top: calc(-12px + 50px);
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 15px;
  -webkit-box-shadow: 2px 4px 6px rgba(49, 49, 49, 0.2);
  box-shadow: 2px 4px 6px rgba(49, 49, 49, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

#chat-menu-wrap .menu ul {
  list-style: none;
}

#chat-menu-wrap .menu ul li {
  margin: 15px 0;
}

#chat-menu-wrap .menu ul li .link {
  text-decoration: none;
  color: rgba(49, 49, 49, 0.85);
  opacity: 0;
  visibility: hidden;
}

#chat-menu-wrap .toggler {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  z-index: 2;
}

#chat-menu-wrap .toggler:hover + .dots > div,
#chat-menu-wrap .toggler:hover + .dots > div:after,
#chat-menu-wrap .toggler:hover + .dots > div:before {
  background-color: rgba(49, 49, 49, 0.6);
}

#chat-menu-wrap .toggler:checked + .dots > div {
  -webkit-transform: translateX(calc(((25px / 2) - (6px / 2)) * -0.7071067812))
    translateY(calc(((25px / 2) - (6px / 2)) * -0.7071067812));
  -ms-transform: translateX(calc(((25px / 2) - (6px / 2)) * -0.7071067812))
    translateY(calc(((25px / 2) - (6px / 2)) * -0.7071067812));
  transform: translateX(calc(((25px / 2) - (6px / 2)) * -0.7071067812))
    translateY(calc(((25px / 2) - (6px / 2)) * -0.7071067812));
}

#chat-menu-wrap .toggler:checked + .dots > div:after {
  -webkit-transform: translateX(calc(((25px / 2) - (6px / 2)) * 0.7071067812))
    translateY(calc((2 * (25px / 2) - (6px / 2)) * 0.7071067812));
  -ms-transform: translateX(calc(((25px / 2) - (6px / 2)) * 0.7071067812))
    translateY(calc((2 * (25px / 2) - (6px / 2)) * 0.7071067812));
  transform: translateX(calc(((25px / 2) - (6px / 2)) * 0.7071067812))
    translateY(calc((2 * (25px / 2) - (6px / 2)) * 0.7071067812));
}

#chat-menu-wrap .toggler:checked + .dots > div:before {
  -webkit-transform: translateX(
      calc(2 * (((25px / 2) - (6px / 2)) * 0.7071067812))
    )
    translateY(
      calc(((25px / 2) - (6px / 2)) - (((25px / 2) - (6px / 2)) * 0.7071067812))
    );
  -ms-transform: translateX(calc(2 * (((25px / 2) - (6px / 2)) * 0.7071067812)))
    translateY(
      calc(((25px / 2) - (6px / 2)) - (((25px / 2) - (6px / 2)) * 0.7071067812))
    );
  transform: translateX(calc(2 * (((25px / 2) - (6px / 2)) * 0.7071067812)))
    translateY(
      calc(((25px / 2) - (6px / 2)) - (((25px / 2) - (6px / 2)) * 0.7071067812))
    );
}

#chat-menu-wrap .toggler:checked:hover + .dots > div,
#chat-menu-wrap .toggler:checked:hover + .dots > div:after,
#chat-menu-wrap .toggler:checked:hover + .dots > div:before {
  background-color: rgba(49, 49, 49, 0.6);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#chat-menu-wrap .toggler:checked ~ .menu {
  opacity: 1;
  visibility: visible;
  width: 150px;
  height: 130px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#chat-menu-wrap .toggler:checked ~ .menu ul .link {
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.5s ease 0.3s;
  -o-transition: 0.5s ease 0.3s;
  transition: 0.5s ease 0.3s;
}

#chat-menu-wrap .toggler:checked ~ .menu ul .link:hover {
  color: #2980b9;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

#chat-menu-wrap .toggler:not(:checked) ~ .menu {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#chat-menu-wrap .toggler:not(:checked) ~ .menu ul .link {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
}

.toggle {
  cursor: pointer;
  display: inline-block;
}

.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 58px;
  height: 32px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}
.toggle-switch:before,
.toggle-switch:after {
  content: "";
}
.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.toggle-checkbox:checked + .toggle-switch {
  background: #8f20c6;
}
.toggle-checkbox:checked + .toggle-switch:before {
  left: 30px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}
.btn-primary {
  background-color: #8f20c6 !important;
  border-color: #8f20c6;
}
::v-deep .filter-dropdown-setting button {
  color: #4f5d73;
}
</style>
